<template>
  <div class="container">
    <h4 class="text-center my-4">Свободное время (расписание)</h4>

    <PrimePreloader v-if="loading"/>
    <div v-else class="my-3 row">


      <div v-for="(day, dayIndex) in days" :key="dayIndex" class="my-4 col-md-4">
        <h4>
          {{ day.name_ru }}
        </h4>



        <div class="table-responsive my-2">
          <table class="table table-striped table-bordered">
            <thead>
            <tr>
              <th scope="col" style="width: 160px">Время</th>
              <th scope="col">Выбор</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(intervalTime, intervalTimeIndex) in scheduleIntervalTimes" :key="intervalTimeIndex">
              <th scope="row">{{ intervalTime.time }}</th>
              <th scope="row">
                <input class="form-check-input" type="checkbox"
                       :checked="teacherSchedule_form.ppsFreetime.some(i=>i.schedule_interval_time_id==intervalTime.id && i.sp_days_id == day.id)"
                       @change="changePpsFreetime(day.id, intervalTime.id, intervalTime.academic_year_id)">
              </th>
            </tr>
            </tbody>
          </table>
        </div>

      </div>


    </div>

    <div class="row mt-4">
      <div class="col-md-12">
        <div class="text-center">
          <Button icon="pi pi-save" :loading="btnSaveDisabled" label="Сохранить" @click="submitPpsFreetimes"/>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapActions, mapState} from 'vuex'


export default {
  name: 'TeacherSchedule',
  data() {
    return {
      loading: true,
      checkedFreetimes: [],
      btnSaveDisabled: false
    }
  },
  computed: {
    ...mapState('managerSchedule', ['days', 'scheduleIntervalTimes']),
    ...mapState('teacherSchedule', ['teacherSchedule_form']),
  },
  methods: {
    ...mapActions('managerSchedule', ['GET_DAYS', 'GET_SCHEDULE_INTERVAL_TIMES']),
    ...mapActions('teacherSchedule', ['POST_PPS_FREETIME', 'GET_PPS_FREETIME', 'DELETE_PPS_FREETIME']),

    async changePpsFreetime(sp_days_id, schedule_interval_time_id, academic_year_id) {
      console.log(sp_days_id, 'sp_days_id')
      console.log(schedule_interval_time_id, 'schedule_interval_time_id')
      console.log(academic_year_id, 'academic_year_id')

      let deleteId = this.teacherSchedule_form?.ppsFreetime?.find(i=>i.sp_days_id == sp_days_id && i.schedule_interval_time_id == schedule_interval_time_id)?.id


      if (deleteId) {
        console.log(deleteId, 'deleteId')
        await this.DELETE_PPS_FREETIME(deleteId)
      }
      else if(this.checkedFreetimes.find(i=>i.sp_days_id == sp_days_id && i.schedule_interval_time_id == schedule_interval_time_id)) {
        let index = this.checkedFreetimes.findIndex(i=>i.sp_days_id == sp_days_id && i.schedule_interval_time_id == schedule_interval_time_id)
        this.checkedFreetimes.splice(index, 1);

      }
      else {
        this.checkedFreetimes.push({
          sp_days_id: sp_days_id,
          schedule_interval_time_id: schedule_interval_time_id,
          academic_year_id: academic_year_id
        })
      }

      console.log(this.checkedFreetimes, 'checkedFreetimes')
    },


    async submitPpsFreetimes() {
      this.btnSaveDisabled = true

      let form = this.checkedFreetimes
      console.log(form, 'submit form')

      let postData = await this.POST_PPS_FREETIME(form)
      if (postData) {

        await this.GET_PPS_FREETIME()
        this.$message({text: 'Свободное время выбрано'})
        //this.$toast.add({severity:'success', summary:'Принято', detail:'Вы приняли', life: 3000});
      }
      this.checkedFreetimes = []
      this.btnSaveDisabled = false
    },

  },
  async mounted() {
    await this.GET_DAYS()
    await this.GET_SCHEDULE_INTERVAL_TIMES()
    await this.GET_PPS_FREETIME()


    console.log(this.days)
    console.log(this.scheduleIntervalTimes)

    this.loading = false
  },
}

</script>